import React, { useState } from "react";
import { navigate } from "gatsby";
import { Form, Input, Radio } from "antd";
import "antd/dist/antd.css";
import Button from "antd/lib/button";
import PropTypes from "prop-types";
import * as axios from "axios";

const { TextArea } = Input;

const Feedback = props => {
  const { theme, form } = props;

  const [error, setError] = useState(null);

  const { getFieldDecorator } = form;

  function handleNetworkError(data) {
    return function(error) {
      console.error("Error submitting data", error);
      setError(data);
    };
  }

  function handleSubmit(e) {
    e.preventDefault();
    setError(null);

    console.log("props", props);
    form.validateFields((err, values) => {
      if (!err) {
        axios
          .post("/api/feedback", {
            name: values.name,
            position: values.position,
            feedback: values.feedback,
            testimonial: values.testimonial
          })
          .then(() => {
            console.log("Form submission success");
            navigate("/thanks");
          })
          .catch(handleNetworkError(values));
      }
    });
  }

  return (
    <div className={"form"}>
      <p>
        Hi! Danke, dass Du Dir die Zeit genommen hast! Ich würde mich sehr über ein ehrliches
        Feedback freuen. Sag mir, was Dir an unserer Zusammenarbeit gefallen hat, oder was nicht so
        gut gelaufen ist. So kann ich meine Arbeit in Zukunft noch besser machen.
      </p>
      <p className={"small"}>
        * Du kannst den Feedback auch gerne anonym abgeben, lasse dafür einfach den Namen und die
        Position leer.
      </p>
      <Form layout={"vertical"} onSubmit={handleSubmit} autoComplete="off">
        <Form.Item label="Name">{getFieldDecorator("name")(<Input />)}</Form.Item>
        <Form.Item label="Position/Rolle im Unternehmen">
          {getFieldDecorator("position")(<Input />)}
        </Form.Item>
        <Form.Item label="Wie würdest Du meine Arbeit bewerten?">
          {getFieldDecorator("feedback", {
            rules: [
              {
                required: true,
                message: "Bitte gib hier Dein Feedback ein",
                whitespace: true
              }
            ]
          })(<TextArea rows={10} name={"feedback"} />)}
        </Form.Item>
        <Form.Item
          label="Darf ich Dein Feedback ggf. als Testimonial auf der Webseite veröffentlichen?
        Ich wäre für die Erlaubnis sehr dankbar."
        >
          {getFieldDecorator("testimonial", { initialValue: "yes" })(
            <Radio.Group className={"feedback--testimonials"}>
              <Radio.Button value={"yes"}>Ja &#128512;</Radio.Button>
              <Radio.Button value={"no"}>Nein &#128543;</Radio.Button>
            </Radio.Group>
          )}
        </Form.Item>
        <Form.Item>
          {error && (
            <div className="errorBox">
              Leider ist bei der Übertragung ein Fehler aufgetreten! Du kannst jedoch das Feedback
              per E-Mail schicken. Klick einfach auf den Link unten, Deine Eingaben werden in die
              E-Mail übernommen.
              <br />
              <br />
              <a
                href={`mailto:feedback@hardtsolutions.de?subject=Feedback&body=${JSON.stringify(
                  error
                )}`}
              >
                Feedback per E-Mail verschicken
              </a>
            </div>
          )}
          <Button type="primary" htmlType="submit">
            Senden
          </Button>
        </Form.Item>
      </Form>

      {/*language=SCSS*/}
      <style jsx>{`
        p {
          margin-bottom: 30px;
          font-size: 1.2em;
        }

        .small {
          font-size: 1em;
        }

        .form {
          :global(.feedback--testimonials label) {
            font-size: 18px;
          }

          :global(.ant-radio-button-wrapper-checked:first-child) {
            border-color: ${theme.color.neutral.black};
          }

          :global(.ant-radio-button-wrapper-checked) {
            border-color: ${theme.color.neutral.black};
            color: ${theme.color.neutral.black};
            box-shadow: -1px 0 0 0 ${theme.color.neutral.black};
          }

          :global(.ant-radio-button-wrapper:hover) {
            color: ${theme.color.neutral.black};
          }
        }

        .form {
          background: transparent;
        }
        .form :global(.ant-row.ant-form-item) {
          margin: 0 0 1em;
        }
        .form :global(.ant-row.ant-form-item:last-child) {
          margin-top: 1em;
        }
        .form :global(.ant-form-item-control) {
          line-height: 1em;
        }
        .form :global(.ant-form-item-label) {
          line-height: 1em;
          margin-bottom: 0.5em;
        }
        .form :global(.ant-form-item) {
          margin: 0;
        }
        .form :global(.ant-input) {
          appearance: none;
          height: auto;
          font-size: 1.2em;
          padding: 0.5em 0.6em;
        }
        .form :global(.ant-btn-primary) {
          min-width: 10.1em;
          height: auto;
          font-size: 1.2em;
          padding: 0.5em 3em;
          background: ${theme.color.brand.primary};
          border: 1px solid ${theme.color.brand.primary};
        }
        .form :global(.ant-form-explain) {
          margin-top: 0.2em;
        }

        .errorBox {
          margin-bottom: 10px;
          padding: 10px;
          background-color: ${theme.color.special.attention};
          color: ${theme.color.neutral.white};

          a {
            color: ${theme.color.neutral.white};
            text-decoration: underline;

            &:hover {
              color: ${theme.color.neutral.gray.b};
            }
          }
        }

        @from-width desktop {
          .form :global(input) {
            max-width: 50%;
          }
        }
      `}</style>
    </div>
  );
};

Feedback.propTypes = {
  form: PropTypes.object,
  theme: PropTypes.object
};

const FeedbackForm = Form.create({ name: "feedback" })(Feedback);

export default FeedbackForm;
